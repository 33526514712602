@import "_imports";

html,
body {
    @include font-size(rem(16));
    @extend %dsr;
    color: $black;
    height: 100%;
}
body {
    display: flex;
    flex-direction: column;
}
header {
}
main {
}
footer {
    .footer-links {
        li{
            a { color: $nero; @include font-size(rem(14));
                &:hover {  }
            }
        }
    }
}

/**************************************************
 * pagination
 **************************************************/
 .pagination {
    .page-link { @include font-size(rem(14)); color: $midnight-express; border-color: #D0D5DD; z-index: 0;
        &:focus, &:hover { background: #F9FAFB; }
    }
    li {
        &.active {
            .page-link { background: #F9FAFB; }
        }

        &.disabled {
            .page-link { background: $white; }
        }
    }
}
/**************************************************
 * breadcrumb
 *************************************************  */
 .breadcrumb { margin: 1.5rem 0 !important;
    // @media(max-width: $small-medium-break) { justify-content: center; }
    // .breadcrumb-item + .breadcrumb-item { padding-left: 0; }
    // .breadcrumb-item + .breadcrumb-item::before { content: none; }
    li{ @include font-size(rem(14)); color: $black;
        &:last-child{
            a { }
        }
        a { @include font-size(rem(14)); text-decoration: none; color: $black; }
        // svg, i { @include font-size(rem(14)); vertical-align: middle; color: $black; }
    }
}
/**************************************************
 * custom css below
 **************************************************/
.full-container { width: calc(100vw); margin: 0 calc(-50vw + 50%); }
.container{ width: 100%; max-width: 100%;
    @media(min-width: $x-large-break) { max-width: 1170px; }
}
a { text-decoration: none; }
.star-icon { width: 20px; height: 20px; background: $danger; }
.category-link { color: $border; 
    svg { fill: $border; }
    &:hover { color: $primary;
        svg { fill: $primary; }
    }
}
.deals-img { height: 100%;
    @media(max-width: $big-break) { height: auto !important; }
}
.stars {
    i { margin-right: 1px; }
}
#showTopRating{ --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); --bs-form-check-bg: #F2F4F7;
    &:checked { background-color: #34A853; border-color: #34A853; --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
    &:focus { box-shadow: none; border-color: var(--bs-border-color); --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
}
.select2-wrap {
    &.is-invalid { border-color: var(--bs-form-invalid-border-color) !important; }
    .form-floating {
        .select2 {
            .select2-selection { height: $form-floating-height; padding: $form-floating-padding-y $form-floating-padding-x;
                > .select2-selection__rendered {
                    margin-top: 1.25rem; @include font-size(rem(16));
                }
            }
        }
    }
    .select2 { width: auto !important; 
        .select2-selection { border: 0; border-radius: 0; @include font-size(rem(14)); min-height: auto; @extend .py-0; display: flex; align-items: center; box-shadow: none !important; }
    }
}
.select2-container {
    .select2-results {
        ul {
            li { @include font-size(rem(14)); }
        }
    }
}
.nav-plans{ background: #F2F4F7;
    .nav-item {
        .nav-link { color: $black;
            &.active { background: $secondary; color: $white; }
        }
    }
}
.search-box { box-shadow: 0px 3px 11.5px 0px rgba($secondary, .12);  }
.hero-search-banner {
    @media(max-width: $large-break) {min-height: auto !important; }
}
.check-icon { width: 24px; height: 24px; border-radius: 50%; font-size: 14px; color: $white; background: $primary; text-align: center; line-height: 24px; }
.plans-content {
    .card {
        &.basic { @include transition(all .5s ease-in-out); 
            &:hover { box-shadow: 0px 8px 32px 0px rgba(#3887BF, .20); @include transition(all .5s ease-in-out); }
            .check-icon { background: #3887BF; }
            .plan-price { color: #3887BF; }
            .plan-link { color: #3887BF; background: #C7DEF0; border-color: transparent; }
            .plan-text { color: $midnight-express; }
            .disabled { 
                .check-icon { background: transparent; color: #95C2E4; }
                .plan-text { color: #D0D5DD; }
            }
        }
        &.plus { @include transition(all .5s ease-in-out);
            &:hover { box-shadow: 0px 8px 32px 0px rgba(#BC38BF, .20); @include transition(all .5s ease-in-out); }
            .check-icon { background: #BC38BF; }
            .plan-price { color: #BC38BF; }
            .plan-link { color: #BC38BF; background: #F0C7E5; border-color: transparent; }
            .plan-text { color: $midnight-express; }
            .disabled { 
                .check-icon { background: transparent; color: #E495D7; }
                .plan-text { color: #D0D5DD; }
            }
        }
        &.business { @include transition(all .5s ease-in-out);
            &:hover { box-shadow: 0px 8px 32px 0px rgba(#F6BF31, .20); @include transition(all .5s ease-in-out); }
            .check-icon { background: #F6BF31; }
            .plan-price { color: #F6BF31; }
            .plan-link { color: #C5854A; background: #F0E7C7; border-color: transparent; }
            .plan-text { color: $midnight-express; }
            .disabled { 
                .check-icon { background: transparent; color: #F6BF31; }
                .plan-text { color: #D0D5DD; }
            }
        }
    }
}

.services-pills{
    .service-pill { border: 0;
        &:hover {
            &.pill-business { background: #FF8F6C; }
            &.pill-events { background: #FDEBAE; }
            &.pill-deals { background: #E7F7C4; }
            &.pill-jobs { background: #D7EFFF; }
        }
    }
    .btn-check{
        &:checked {
            + .service-pill { @extend %dsb;
                &:hover { background: none; }
            }
        }
    }
}

.category-listings{
    .category-link { border: 1px solid #ECECEC;
        &:hover { border-color: $primary; }
    }
}

#accordionFaq {
    .accordion-item { border: 2px solid $pattens-blue; @include transition(all .25s ease-in-out);
        &:has(> .collapse.show) { border-color: $primary; @include transition(all .25s ease-in-out); }
        .arrow-icon { width: 34px; height: 34px; border-radius: 50%; transform: rotate(90deg); color: $white; background: $primary; @include transition(all .5s ease-in-out); }
        .collapsed {
            .arrow-icon { transform: rotate(0); color: $primary; background: $white; @include transition(all .5s ease-in-out); }
        }
    }
}

.product-thumbs-wrapper {
    .swiper-button-prev { /*margin: 0; top: inherit;*/ width: 48px; left: inherit; height: 48px; z-index: 0; color: $primary; margin-top: 0; background: $old-lace;
        &:after { content: none; }
    }
    .swiper-button-next { /*margin: 0; bottom: inherit; top: inherit;*/ right: 0; width: 48px; height: 48px; z-index: 0; color: $primary; margin-top: 0; background: $old-lace;
        &:after { content: none; }
    }
}
.business-header { position: sticky;
    @media(max-width: $small-medium-break) { position: inherit; }
}
.business-review-tab-nav{
    .nav-item {
        .nav-link { color: $comet;
            &.active { color: $primary; }
        }
    }
}

.search-form {
    .input-group {
        @media(max-width: $medium-break) { padding: 0.5rem; border-radius: var(--bs-border-radius-lg); border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color); }
    }
}