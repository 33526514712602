/**************************************************
* breakpoints
**************************************************/
$x-small-break: 400px;
$small-break: 480px;
$small-medium-break: 575px;
$medium-break: 768px;
$big-break: 991px;
$large-break: 1024px;
$x-large-break: 1200px;
$xl-large-break: 1395px;


@function rem($pixel-size, $base-size: 16px) {
    @if (unitless($pixel-size)) {
        $pixel-size: $pixel-size * 1px;
    }

    @return $pixel-size / $base-size * 1rem;
}
@mixin uno-font-faces($font-label, $font-name, $font-weight, $italic: false) {

    #{".ff-"}#{$font-label} {
        font-family: $font-name, sans-serif !important;
        font-weight: $font-weight !important;
        font-optical-sizing: auto;
        @if($italic){
            font-style: italic;
        }
    }

    %#{$font-label} {
        font-family: $font-name, sans-serif !important;
        font-weight: $font-weight !important;
        font-optical-sizing: auto;
        @if($italic){
            font-style: italic;
        }
    }
}

@mixin uno-font-sizes($min: 1, $max: 100) {
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include media-breakpoint-up($breakpoint) {
            $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
            @for $i from $min through $max {
                .fs#{$infix}-#{$i} {
                    @include font-size(rem($i));
                }
            }
        }
    }
}

@mixin placeholder { $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
    @each $placeholder in $placeholders {
        &:#{$placeholder}-placeholder {
            @content;
        }
    }
}

@keyframes loader-spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
        @each $key, $ratio in $aspect-ratios {
            .ratio#{$infix}-#{$key} {
                --bs-aspect-ratio: #{$ratio};
            }
        }
    }
}

@include uno-font-faces('dsr', 'DM Sans', 400);
@include uno-font-faces('dsm', 'DM Sans', 500);
@include uno-font-faces('dssb', 'DM Sans', 600);
@include uno-font-faces('dsb', 'DM Sans', 700);
@include uno-font-faces('dseb', 'DM Sans', 800);
@include uno-font-faces('dsbk', 'DM Sans', 900);
@include uno-font-sizes();

.object-fit {
    &-cover { object-fit: cover; }
    &-contain { object-fit: contain; }
}
.bg{
    &-bottom { background-position: bottom center; }
    &-cover { background-size: cover; }
    &-no-repeat { background-repeat: no-repeat; }
}

a { text-decoration: none;}
*:focus {
    box-shadow: none !important;
}

.min{
    &-w{
        &-auto { min-width: auto; }
    }
    &-h{
        &-auto { min-height: auto; }
    }
}

.btn {
    &:disabled, &.disabled { cursor: not-allowed; }
    &-primary { --bs-btn-color: #fff; --bs-btn-hover-color: #fff; --bs-btn-active-color: #fff; --bs-btn-disabled-color: #fff; --bs-btn-hover-bg: #E13015; }
    &-outline{
        &-old-lace { --bs-btn-color: #F0491F; --bs-btn-hover-color: #F0491F; --bs-btn-active-color: #F0491F; --bs-btn-bg: #FFFFFF; --bs-btn-hover-bg: #FFFFFF; --bs-btn-active-bg: #FFFFFF; --bs-btn-border-color: #FDE5D7; --bs-btn-active-border-color: #F0491F; --bs-btn-hover-border-color: #F0491F;
            &:disabled, &.disabled { color: #F0491F; border-color: #FDE5D7; }
        }
    }
    &-old-lace { --bs-btn-color: #F0491F; --bs-btn-hover-color: #F0491F; --bs-btn-active-color: #F0491F; --bs-btn-hover-bg: #FDE5D7; --bs-btn-border-color: #FEF4EE; --bs-btn-hover-border-color: #F0491F; }
}
.line-clamp { 
    &-1 { display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; overflow: hidden; }
    &-2 { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; }
    &-3 { display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; overflow: hidden; }
    &-4 { display: -webkit-box; -webkit-line-clamp: 4; -webkit-box-orient: vertical; overflow: hidden; }
}
.rounded-top{
    &-start-0 { border-top-left-radius: 0 !important; }
    &-end-0 { border-top-right-radius: 0 !important; }
}
.rotate{
    &-n45 { transform: rotate(-45deg); }
}

.swiper-pagination{ position: relative;
    &-bullet { background: #D9D9D9; opacity: 1; @include transition(all .5s ease-in-out); width: 7px; height: 7px; border-radius: 4px;
        &-active { background: $primary; @include transition(all .5s ease-in-out); }
    }
}

.progress{ --bs-progress-bar-bg: #34A853; --bs-progress-bg: #F2F4F7; --bs-progress-height: 8px; --bs-border-radius: 4px;
    .progress-bar { border-radius: var(--bs-border-radius); }
}

.hover-shadow { @include transition(all .5s ease-in-out);
    &:hover { box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, .10); @include transition(all .5s ease-in-out); }
}

.text-pre-wrap { white-space: pre-wrap; }

input{
    &::-webkit-inner-spin-button, 
    &::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
    [type=number] {
        -moz-appearance: textfield;
    }
}
.text-animation-wrap{ min-width: 130px;
    .text-animation { position: absolute; animation: text-slide-up 10s ease-in-out infinite;
        @keyframes text-slide-up {
            0%, 25% { top: 0; } 
            30%, 55% { top: -100%; }
            60%, 85% { top: -200%; }
            90%, 100% { top: 0%; }
        }
    }
}

.loading { position: relative;
    &:before { position: absolute; content: ''; left: 0; top: 0;right: 0;bottom: 0;background: rgba($white, .7); z-index: 2; }
    &:after { position: absolute; content: ''; left: 50%; top: 50%; height: 30px; width: 30px; border-radius: 50%; border: 3px solid $primary; border-top-color: transparent !important; z-index: 3; animation: loader-spin 800ms infinite linear; }
}

img.lazyload:not([src]) {
    visibility: hidden;
}

[v-cloak] { display: none; }
