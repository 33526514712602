$primary: #F0491F;
$secondary: #FF6E40;
$border: #6A6A6A;
$border-color: #ECECEC;
$black: #4F4F4F;
$danger: #FF4F4F;
$success: #009B0A;
$nero: #222222;
$white-smoke: #F7F7F7;
$regal-blue: #1E3D59;
$midnight-express: #191F33;
$old-lace: #FEF4EE;
$comet: #636A80;
$pattens-blue: #EBEEF7;
$shady-lady: #979797;
$tenne-tawny: #CF4E00;
$dr-white: #FAFAFA;
$race-the-sun: #ECF5D2;
$olive-drab: #668E22;
$girlie: #FFCCCC;
$ripasso: #992f2f;
$creamy-cloud-dream: #FFF8E0;
$errigal-white: #F2F2F2;
$blue-bay: #5DA2D3;
$chef-hat: #F2F4F7;

$grid-breakpoints: (
  xs: 0,
  ms: 426px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$position-values: (
    auto: auto,
    0: 0,
    50: 50%,
    100: 100%
);

$enable-negative-margins: true;

@import "~bootstrap5/scss/functions";
@import "~bootstrap5/scss/variables";

$custom-colors: (
    "border" : $border,
    "nero": $nero,
    "white-smoke": $white-smoke,
    "regal-blue" : $regal-blue,
    "midnight-express" : $midnight-express,
    "old-lace" : $old-lace,
    "comet" : $comet,
    "pattens-blue" : $pattens-blue,
    "shady-lady" : $shady-lady,
    "tenne-tawny" : $tenne-tawny,
    "dr-white" : $dr-white,
    "race-the-sun" : $race-the-sun,
    "olive-drab" : $olive-drab,
    "girlie" : $girlie,
    "ripasso" : $ripasso,
    "creamy-cloud-dream" : $creamy-cloud-dream,
    "errigal-white": $errigal-white,
    "blue-bay": $blue-bay,
    "chef-hat": $chef-hat,
);

$custom-ratios: (
    "16x10": calc(10 / 16 * 100%),
);

$theme-colors: map-merge($theme-colors, $custom-colors);
$aspect-ratios: map-merge($aspect-ratios, $custom-ratios);

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import "~bootstrap5/scss/bootstrap";
@import "_customs";
